<template>
  <div class="product-listing">
    <AdvertisingBanner class="col-12 my-30 mt-lg-0" />
    <SearchPageContent />
  </div>
</template>

<script>
import SearchPageContent from "@/base/core/components/SearchPageContent";
import AdvertisingBanner from "@/base/core/components/AdvertisingBanner";
export default {
  name: "ProductsListingSearch",
  components: {
    SearchPageContent,
    AdvertisingBanner,
  },
  computed: {},
};
</script>

