<template>
  <div class="productFilterContent filter-content">
    <div class="d-lg-none filter-top">
      <span class="filter-collapse-heading">{{ $t("filters") }}</span>
      <b-link class="lnr lnr-cross" @click.prevent="toggleFilter"></b-link>
    </div>
    <div class="category" v-if="!dontShowActiveFilters">
      <span class="filter--block-heading">{{ searchWord }}</span>
    </div>
    <div
      class="active-filters"
      v-if="isPriceFilterActive || filterItemsSelected.length"
    >
      <b-link
        class="filter--block-heading filter--collapse"
        v-b-toggle.active-filters
        >{{ $t("used_filters") }}:</b-link
      >
      <b-link href="#" class="delete-active-filters"
        ><i class="lnr lnr-cross"></i
      ></b-link>
      <b-collapse visible id="active-filters">
        <ul class="active-filters-list">
          <li v-if="isPriceFilterActive">
            <b-link @click="removePriceFilter">
              <i class="lnr lnr-cross"></i>
            </b-link>
            <span class="active-filter--name">
              {{ `${priceFilterLabel ? priceFilterLabel : "Prijs"}` }}:
              {{ priceFilter[0] + " - " + priceFilter[1] }}
            </span>
          </li>
          <li
            v-for="(activeFilterItem, index) of filterItemsSelected"
            :key="index"
          >
            <b-link @click="removeSelectedItem(activeFilterItem.value)" href="#"
              ><i class="lnr lnr-cross"></i
            ></b-link>
            <span class="active-filter--name"
              >{{ activeFilterItem.type_label }}:
              {{ activeFilterItem.label }}</span
            >
          </li>
        </ul>
      </b-collapse>
    </div>
    <div v-for="prodFilter of productFilters" :key="prodFilter.attribute_code">
      <div class="price" v-if="prodFilter.attribute_code == 'price'">
        <b-link
          class="filter--block-heading filter--collapse"
          v-b-toggle.price-filter
          >{{ prodFilter.label }}</b-link
        >
        <b-collapse visible id="price-filter">
          <div class="range-slider">
            <ClientOnly>
              <VueRangeSlider
                :min="priceFilterMin"
                :max="priceFilterMax"
                :enable-cross="enableCross"
                :formatter="formatter"
                v-model="priceFilter"
                :logarithmic="true"
                :tooltip="null"
                :lazy="true"
              >
              </VueRangeSlider>
            </ClientOnly>

            <div class="range-values d-flex justify-content-between">
              <span>€{{ priceFilter[0] }}</span>
              <span>€{{ priceFilter[1] }}</span>
            </div>
          </div>
        </b-collapse>
      </div>

      <div
        class="brands"
        v-if="
          prodFilter.attribute_code != 'size' &&
          prodFilter.attribute_code != 'price'
        "
      >
        <b-link
          class="filter--block-heading filter--collapse"
          v-b-toggle.brands-filter
          >{{ $t(prodFilter.label) }}</b-link
        >
        <b-collapse visible id="brands-filter" class="brands-filter">
          <div
            class="d-flex justify-content-between"
            v-for="(brandsFilterItem, index) in prodFilter.options"
            :key="index"
          >
            <label
              :for="brandsFilterItem.value"
              class="d-flex align-items-center"
            >
              <input
                type="checkbox"
                :key="brandsFilterItem.value"
                :name="brandsFilterItem.label"
                :id="brandsFilterItem.value"
                :ref="brandsFilterItem.value"
                :value="
                  `attribute_code:` +
                  prodFilter.attribute_code +
                  `,type_label:` +
                  prodFilter.label +
                  `,value:` +
                  brandsFilterItem.value +
                  `,label:` +
                  brandsFilterItem.label
                "
                @click="onChangeFilter($event)"
              />
              <span class="fake-input"></span>
              <span class="fake-label">{{ brandsFilterItem.label }}</span>
            </label>
            <span class="number-available">({{ brandsFilterItem.count }})</span>
          </div>
        </b-collapse>
      </div>
      <div class="size" v-if="prodFilter.attribute_code == 'size'">
        <b-link
          class="filter--block-heading filter--collapse mb-15"
          v-b-toggle.size-filter
          >Kies je maat</b-link
        >
        <b-collapse visible id="size-filter" class="size-filter">
          <ul class="available-sizes">
            <li
              v-for="(sizeFilterItem, index) in prodFilter.options"
              :key="index"
            >
              <label
                :for="sizeFilterItem.name"
                class="d-flex align-items-center"
              >
                <input
                  type="checkbox"
                  :key="sizeFilterItem.value"
                  :id="sizeFilterItem.value"
                  :name="sizeFilterItem.label"
                  :ref="sizeFilterItem.value"
                  :value="
                    `attribute_code:` +
                    prodFilter.attribute_code +
                    `,type_label:` +
                    prodFilter.label +
                    `,value:` +
                    sizeFilterItem.value +
                    `,label:` +
                    sizeFilterItem.label
                  "
                  @change="onChangeFilter($event)"
                />
                <span class="fake-input"></span>
                <span class="fake-label">{{ sizeFilterItem.name }}</span>
              </label>
            </li>
          </ul>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import ClientOnly from "vue-client-only";
import VueRangeSlider from "vue-slider-component/dist-css/vue-slider-component.umd.min.js";
export default {
  name: "SearchProductFilterContent",
  components: {
    ClientOnly,
    VueRangeSlider,
  },
  props: {
    dontShowActiveFilters: {
      type: Boolean,
    },
  },
  created() {
    this.min = 0;
    this.max = 250;
    this.enableCross = false;
    this.formatter = "€ {value}";
    this.tooltipDir = "bottom";
  },
  methods: {
    onChangeFilter(event) {
      const evSplit = event.target.value.split(",");
      const filter = {
        attribute_code: "",
        type_label: "",
        value: "",
        label: "",
      };
      evSplit.forEach((element) => {
        const part = element.split(":");
        if (part[0] == "attribute_code") {
          filter.attribute_code = part[1];
        } else if (part[0] == "type_label") {
          filter.type_label = part[1];
        } else if (part[0] == "value") {
          filter.value = part[1];
        } else if (part[0] == "label") {
          filter.label = part[1];
        }
      });
      if (typeof filter.value != "undefined") {
        if (event.target.checked == true) {
          this.$store.commit("search/setActiveFilter", filter);
        } else {
          this.$store.commit("search/removeActiveFilter", filter);
        }
      }
      this.updateUrlFilters();
    },
    removeSelectedItem(id) {
      const filter = { value: id };
      if (this.$refs[id] && this.$refs[id][0]) {
        this.$refs[id][0].checked = false;
      }
      this.$store.commit("search/removeActiveFilter", filter);
      this.updateUrlFilters();
    },
    removePriceFilter() {
      const priceFilter = [this.priceFilterMin, this.priceFilterMax];
      this.$store.commit("search/setPriceFilter", priceFilter);

      const query = { ...this.$route.query };
      delete query["filter-price"];
      delete query["page"];

      this.$router.replace({ path: this.$route.path, query });
    },
    toggleFilter(event) {
      this.$emit("clicked", event);
    },
    updateUrlFilters() {
      const activeFilters = {};
      this.filterItemsSelected.forEach((val) => {
        if (activeFilters[val.attribute_code]) {
          activeFilters[val.attribute_code].push(val.value);
        } else {
          activeFilters[val.attribute_code] = [val.value];
        }
      });

      const query = { ...this.$route.query };
      for (const q in query) {
        if (q.startsWith("filter-") && q !== "filter-price") {
          delete query[q];
        }
      }
      for (const attr in activeFilters) {
        query[`filter-${attr}`] = activeFilters[attr].toString();
      }
      delete query["page"];

      this.$router.replace({ path: this.$route.path, query });
    },
  },
  data() {
    return {
      value: [0, 200],
      sliderIsLoaded: false,
      bla: false,
      minAngle: 10,
      maxAngle: 30,
      filterExpanded: false,
    };
  },
  computed: {
    searchWord() {
      if (typeof this.$route.query.q != "undefined") {
        return this.$route.query.q;
      } else {
        return "";
      }
    },
    filterItemsSelected() {
      return this.$store.getters["search/getActiveFilter"];
    },
    isPriceFilterActive() {
      return this.$store.getters["search/getIsPriceFilterActive"];
    },
    priceFilterMin() {
      return this.$store.getters["search/getPriceFilterMin"];
    },
    priceFilterMax() {
      return this.$store.getters["search/getPriceFilterMax"];
    },
    priceFilterLabel() {
      if (this.productFilters) {
        const priceFilter = this.productFilters.find(
          (filter) => filter.attribute_code == "price"
        );
        return priceFilter && priceFilter.label;
      }
      return null;
    },
    priceFilter: {
      get: function () {
        return this.$store.getters["search/getPriceFilter"];
      },
      set: function (val) {
        this.$store.commit("search/setPriceFilter", val);
        const query = { ...this.$route.query };
        if (this.isPriceFilterActive) {
          query["filter-price"] = `${val[0]}-${val[1]}`;
        } else {
          delete query["filter-price"];
        }
        delete query["page"];

        this.$router.replace({ path: this.$route.path, query });
      },
    },
    productFilters: {
      get: function () {
        var prodFilters = this.$store.getters["search/getProductFilters"];
        if (prodFilters != null && Symbol.iterator in Object(prodFilters)) {
          prodFilters.forEach(function (filter, index) {
            prodFilters[index].label =
              filter.label.charAt(0).toUpperCase() + filter.label.slice(1);
            prodFilters[index].options.forEach(
              (option, optIndex) =>
                (prodFilters[index].options[optIndex].label =
                  option.label.charAt(0).toUpperCase() + option.label.slice(1))
            );
          });
        }
        return prodFilters;
      },
      set: function () {},
    },
  },
  updated() {
    if (this.filterItemsSelected && this.filterItemsSelected.length) {
      this.filterItemsSelected.forEach((filter) => {
        if (this.$refs[filter.value] && this.$refs[filter.value][0]) {
          this.$refs[filter.value][0].checked = true;
        }
      });
    }
  },
  watch: {
    filterItemsSelected: function () {},
  },
};
</script>
