<template>
  <section class="page-content">
    <SearchPageContentFilter />
    <SearchFilteredProducts />
    <SearchPageContentFilter />
  </section>
</template>

<script>
import SearchPageContentFilter from "@/base/core/components/SearchPageContentFilter";
import SearchFilteredProducts from "@/base/core/components/SearchFilteredProducts";
export default {
  name: "SearchPageContent",
  components: {
    SearchPageContentFilter,
    SearchFilteredProducts,
  },
};
</script>

